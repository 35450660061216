.component-detail-container {
  $container: &;

  display: grid;
  flex: 1;
  width: 100%;
  height: 100%;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr) 1.5fr;
  gap: 12px 12px;
  position: relative;

  &__detail {
    //@apply flex flex-col bg-bosch-light-blue backdrop-blur-md backdrop-filter bg-opacity-90 relative pb-6 px-6;
    @apply relative self-end flex bg-bosch-light-blue backdrop-blur-md backdrop-filter bg-opacity-90;
  }


  #{$container}__layout-RIGHT &__detail {
    max-height: 100%;
    grid-area: 2 / 8 / 11 / 12;
  }

  #{$container}__layout-LEFT &__detail {
    max-height: 100%;
    grid-area: 2 / 2 / 11 / 6;
  }

  &__headline {
    @apply bosch-text-headline text-white pt-6 pr-4 relative;
  }

  &__detail-inner {
    @apply flex flex-col relative pb-6 px-6 max-h-full;
  }

  &__detail-body {
    @apply bosch-text-body text-white relative overflow-scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      @apply mb-4;
    }

    ul {
      @apply list-square pl-6;
    }

    ol {
      @apply list-decimal pl-6;
    }

    ul, ol {
      @apply list-outside mb-4 mt-2;
      li {
        @apply mb-2;
      }
    }

    strong {
       @apply font-bold;
    }
    
    small {
      @apply text-xs;
    }
  }

  &__close-button {
    @apply absolute text-white top-4 right-4 z-10;
  }

}
