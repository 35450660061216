.component-topview-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr) 1.5fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 12px 12px;
  width: 100%;
  height: 100%;
  flex: 1;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". headline headline headline headline headline headline headline headline headline headline ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . .";

  &__headline {
    @apply bosch-text-headline;
    grid-area: headline;
  }
}
