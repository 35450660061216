ion-button {
  --padding-top: 0;
  --padding-right: 0;
  --padding-bottom: 0;
  --padding-left: 0;
}

.swiper-horizontal.hasPagination {
  @apply pb-14 bg-white;
  --swiper-navigation-size: 1rem;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 12px;
}

.swiper-horizontal.hasPagination:after {
  content: '';
  position: absolute;
  @apply bottom-14 h-px w-full bg-bosch-light-gray z-10;
}

.swiper__button {
  @apply absolute text-bosch-dark-blue top-4 z-10;

  &-close {
    @apply right-4;
  }

  &-fullscreen-toggle {
    @apply right-14 top-5;
  }
}

.subpage-grid-container__fullscreen {
  @apply fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center;
}

.detail-modal__fullscreen {
  .swiper__button {
    @apply fixed;
  }
}
