/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'boschsans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

  --ion-color-primary: #005691;
  --ion-color-primary-rgb: 0,86,145;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #004c80;
  --ion-color-primary-tint: #1a679c;

  --ion-color-secondary: #007bc0;
  --ion-color-secondary-rgb: 0,168,176;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #004c80;
  --ion-color-secondary-tint: #1a679c;

  --ion-color-tertiary: #008ecf;
  --ion-color-tertiary-rgb: 0,142,207;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #007db6;
  --ion-color-tertiary-tint: #1a99d4;

  --ion-color-success: #00884A;
  --ion-color-success-rgb: 0,136,74;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #007841;
  --ion-color-success-tint: #1a945c;

  --ion-color-warning: #FFCF00;
  --ion-color-warning-rgb: 255,207,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b600;
  --ion-color-warning-tint: #ffd41a;

  --ion-color-danger: rgb(234,0,22);
  --ion-color-danger-rgb: 237,0,7;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: rgb(0,86,145);
  --ion-color-danger-tint: #ef1a20;

  --ion-color-medium: #71767C;
  --ion-color-medium-rgb: 113,118,124;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #63686d;
  --ion-color-medium-tint: #7f8489;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 193,199,204;
  --ion-color-light-contrast: #005691;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #aaafb4;
  --ion-color-light-tint: #c7cdd1;
}
