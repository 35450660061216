// grid.layoutit.com?id=wPgQd1A

// homepage-grid-container
.home-grid {
  &-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }
}
