.service-menu-grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr) 1.5fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 12px 12px;
  grid-auto-flow: row;
  height: 100%;
  width: 100%;
  flex: 1;
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". content-headline content-headline content-headline content-headline content-headline content-headline content-headline content-headline content-headline content-headline ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". . . . . . . . . . . .";

  &__headline {
    grid-area: content-headline;
  }

  &__stage {
    display: grid;
    height: 100%;
    width: 100%;
    flex: 1;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 12px 12px;
    grid-auto-flow: row;
    grid-area: stage;

    @for $i from 1 through 19 {
      :nth-child(#{$i}) {
        grid-area: tile-#{$i};
      }
    }
  }
}


.service-menu-grid-container__stage {
  &_1 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-1";
  }

  &_2 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2";
  }

  &_3 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-2 tile-2 tile-2 tile-2 tile-2 tile-2"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3";
  }

  &_4 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-3 tile-3"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4";
  }

  &_5 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-3 tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-3 tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-3 tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-5";
  }

  &_6 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-4 tile-4"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-2 tile-6 tile-6 tile-6 tile-6 tile-6 tile-6"
    "tile-3 tile-3 tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-6 tile-6"
    "tile-3 tile-3 tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-6 tile-6";
  }

  &_7 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-5 tile-5 tile-5 tile-5"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-5 tile-5 tile-5 tile-5"
    "tile-1 tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-5 tile-5 tile-5 tile-5"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-6 tile-6 tile-6 tile-6"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-6 tile-6 tile-6 tile-6"
    "tile-2 tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-3 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-3 tile-3 tile-3 tile-3 tile-3 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7";
  }

  &_8 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6"
    "tile-1 tile-1 tile-1 tile-1 tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6"
    "tile-2 tile-2 tile-2 tile-2 tile-3 tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-7"
    "tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-8"
    "tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-8";
  }

  &_9 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-7"
    "tile-2 tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-8"
    "tile-3 tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-9"
    "tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-9 tile-9 tile-9 tile-9"
    "tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-9 tile-9 tile-9 tile-9";
  }

  &_10 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-1 tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-8"
    "tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-9"
    "tile-2 tile-2 tile-2 tile-2 tile-6 tile-6 tile-6 tile-6 tile-9 tile-9 tile-9 tile-9"
    "tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-10"
    "tile-3 tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-7 tile-10 tile-10 tile-10 tile-10"
    "tile-3 tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-7 tile-10 tile-10 tile-10 tile-10";
  }

  &_11 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-1 tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-8"
    "tile-1 tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-9"
    "tile-2 tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-9"
    "tile-2 tile-2 tile-2 tile-2 tile-6 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-10"
    "tile-3 tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-10"
    "tile-3 tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-11"
    "tile-3 tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-11";
  }

  &_12 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10"
    "tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10"
    "tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-10 tile-10 tile-10"
    "tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11"
    "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-11 tile-11 tile-11"
    "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
    "tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-12 tile-12 tile-12"
    "tile-3 tile-3 tile-3 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-12 tile-12 tile-12";
  }


  &_13 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11"
    "tile-1 tile-1 tile-1 tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11"
    "tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-11 tile-11 tile-11"
    "tile-2 tile-2 tile-2 tile-4 tile-4 tile-4 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
    "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-12 tile-12 tile-12"
    "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-13 tile-13 tile-13"
    "tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-13 tile-13 tile-13"
    "tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-13 tile-13 tile-13";
  }

  &_14 {
    grid-template-areas:
      "tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
      "tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
      "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-12 tile-12 tile-12"
      "tile-2 tile-2 tile-2 tile-6 tile-6 tile-6 tile-9 tile-9 tile-9 tile-13 tile-13 tile-13"
      "tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-13 tile-13 tile-13"
      "tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-10 tile-10 tile-10 tile-14 tile-14 tile-14"
      "tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-14 tile-14 tile-14"
      "tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-14 tile-14 tile-14";
  }

  &_15 {
    grid-template-areas:
    "tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
    "tile-1 tile-1 tile-1 tile-5 tile-5 tile-5 tile-8 tile-8 tile-8 tile-12 tile-12 tile-12"
    "tile-2 tile-2 tile-2 tile-5 tile-5 tile-5 tile-9 tile-9 tile-9 tile-13 tile-13 tile-13"
    "tile-2 tile-2 tile-2 tile-6 tile-6 tile-6 tile-9 tile-9 tile-9 tile-13 tile-13 tile-13"
    "tile-3 tile-3 tile-3 tile-6 tile-6 tile-6 tile-10 tile-10 tile-10 tile-14 tile-14 tile-14"
    "tile-3 tile-3 tile-3 tile-7 tile-7 tile-7 tile-10 tile-10 tile-10 tile-14 tile-14 tile-14"
    "tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-15 tile-15 tile-15"
    "tile-4 tile-4 tile-4 tile-7 tile-7 tile-7 tile-11 tile-11 tile-11 tile-15 tile-15 tile-15";
  }

  &_more {
    @apply flex flex-col;
  }
}
