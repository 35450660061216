// grid.layoutit.com?id=jaQulr9

// subpage-grid-container
.subpage-grid-container {
  display: grid;
  flex: 1;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr 8fr 1.5fr;
  gap: 12px 12px;
  grid-auto-flow: row;
  min-height: 0;
  min-width: 0;
  grid-template-areas:
    ". . . . . . . . . . . ."
    "headline headline headline headline detail detail detail detail detail detail detail detail"
    "body body body body . . . . . . . ."
    "button-0 button-0 button-1 button-1 button-2 button-2 button-3 button-3 button-4 button-4 button-5 button-5";

  &__headline {
    @apply z-10 relative;
    grid-area: headline;
  }

  &__body {
    @apply z-10 relative;
    grid-area: body;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      @apply mb-4;
    }

    ul {
      @apply list-square pl-6;
    }

    ol {
      @apply list-decimal pl-6;
    }

    ul, ol {
      @apply list-outside mb-4 mt-2;
      li {
        @apply mb-2;
      }
    }

    strong {
       @apply font-bold;
    }
    small {
      @apply text-xs;
    }
  }

  &__detail {
    @apply z-10 relative;
    grid-area: detail;
    min-height: 0;
    min-width: 0;
  }

  @for $i from 0 through 5 {
    &__button-#{$i} {
      @apply z-10 relative;
      grid-area: button-#{$i};
    }
  }
}

.swiper-button-disabled {
  display: none;
}

.text-bg-gradient {
  @apply absolute left-0 bottom-0 top-0 z-0 w-1/2;
  background: #e2e6e7;
  background: linear-gradient(90deg, rgba(226, 230, 231, .9) 65%, rgba(226, 230, 231, 0) 100%);
}

.bullet {
  @apply w-2 h-2 ml-6 first:ml-0 rounded-full inline-block;
}
