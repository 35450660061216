.base-grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(10, 1fr) 1.5fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 12px 12px;
  width: 100%;
  height: 100%;
  flex: 1;
  grid-auto-flow: row;
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". headline headline headline headline headline headline headline headline headline headline ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". stage stage stage stage stage stage stage stage stage stage ."
    ". . . . . . . . . . . .";

  &__headline {
    @apply bosch-text-headline;
    grid-area: headline;
  }

  &__stage {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 12px 12px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . ."
      ". . . . . . . . . . . .";
    grid-area: stage;
  }
}
