@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom, 0);
  --safe-area-left: env(safe-area-inset-left, 0);
  --safe-area-right: env(safe-area-inset-right, 0);
  --ion-background-color: linear-gradient(to bottom, #EBEBEB, #E2E3E5);
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

html, body {
  display: flex;

  overflow: hidden;
  height: 100vh;
  width: 100vw;

  @apply bg-bosch-light-gray-w75;
}

html {
  @apply font-boschsans;

  &:lang(zh),
  &:lang(zh) body {
    @apply font-boschsans-zh;
  }

  &:lang(jp),
  &:lang(jp) body {
    @apply font-boschsans-jp;
  }
}

.background-loading {
  &:before {
    content: '';
    color: #005691;
    position: absolute;
    display: flex;
    text-align: center;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiMwMDU2OTEiPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8Y2lyY2xlIHN0cm9rZS1vcGFjaXR5PSIuNSIgY3g9IjE4IiBjeT0iMTgiIHI9IjE4Ii8+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+CiAgICAgICAgICAgICAgICA8YW5pbWF0ZVRyYW5zZm9ybQogICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIKICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiCiAgICAgICAgICAgICAgICAgICAgZnJvbT0iMCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IgogICAgICAgICAgICAgICAgICAgIGR1cj0iMXMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  }
}

ion-loading.my-custom-class {
  z-index: 1000 !important;
}


// IONIC OVERRIDES
ion-button {
  --border-radius: 0;
}

.grid-container-overview__2 {
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }

  a:nth-child(2) {
    grid-area: 1 / 2 / 2 / 2;
  }
}

.grid-container-overview__3 {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }

  a:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }

  a:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.grid-container-overview__4 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  a:nth-child(2) {
    grid-area: 1 / 3 / 4 / 5;
  }

  a:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }

  a:nth-child(4) {
    grid-area: 3 / 2 / 4 / 3;
  }
}

.grid-container-overview__5 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 2 / 3 / 3;
  }

  a:nth-child(2) {
    grid-area: 1 / 3 / 4 / 5;
  }

  a:nth-child(3) {
    grid-area: 3 / 1 / 4 / 3;
  }

  a:nth-child(4) {
    grid-area: 3 / 2 / 2 / 1;
  }
}

.grid-container-overview__6 {
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  a:nth-child(2) {
    grid-area: 3 / 1 / 5 / 2;
  }

  a:nth-child(3) {
    grid-area: 3 / 2 / 5 / 3;
  }

  a:nth-child(4) {
    grid-area: 1 / 3 / 3 / 4;
  }

  a:nth-child(5) {
    grid-area: 1 / 4 / 3 / 5;
  }

  a:nth-child(6) {
    grid-area: 3 / 3 / 5 / 5;
  }
}

.grid-container-overview__7 {
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);

  a:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  a:nth-child(2) {
    grid-area: 3 / 1 / 5 / 2;
  }

  a:nth-child(3) {
    grid-area: 3 / 2 / 5 / 3;
  }

  a:nth-child(4) {
    grid-area: 1 / 3 / 3 / 4;
  }

  a:nth-child(5) {
    grid-area: 1 / 4 / 3 / 5;
  }

  a:nth-child(6) {
    grid-area: 3 / 3 / 4 / 5;
  }

  a:nth-child(7) {
    grid-area: 4 / 3 / 5 / 5;
  }
}

.grid-container-overview__8 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.grid-container-overview__9 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.grid-container-overview__10 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.grid-container-overview__11 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.grid-container-overview__12 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

[data-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

[data-dialog-content] {
  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
}

.is-web {
  [data-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-items: center;
  }

  [data-dialog-content] {
    @apply my-auto mx-auto bg-transparent text-white relative pointer-events-auto p-0 transition-all outline-0;

    &.detail-modal__fullscreen {
      @apply bg-white text-white fixed w-full h-full m-0 p-0 transition-all;
    }
  }
}

.is-native {
  [data-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-items: center;
  }

  [data-dialog-content] {
    @apply bg-white p-6 text-white relative pointer-events-auto;

    &.detail-modal__normal {
      @apply bg-transparent text-white relative m-0 p-0 transition-all;
    }

    &.detail-modal__fullscreen {
      @apply bg-white text-white fixed w-full h-full m-0 p-0 transition-all flex items-center flex-grow;
    }
  }
}

.simplebar-track {
  @apply -left-4 right-auto;
  &.simplebar-vertical {
    width: 7px;
  }

  .simplebar-scrollbar::before {
    @apply bg-bosch-dark-blue opacity-100;
  }
}

.hyphens {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  hyphenate-limit-chars: auto 4 4;
}
