/* BOSCH SANS LATIN */
@font-face {
    font-family: boschsans;
    font-weight: 400;
    src: url(../../public/assets/fonts/boschsans/BoschSans-Regular.woff2) format("woff2");
    font-display: swap
}

@font-face {
    font-family: boschsans;
    font-weight: 400;
    src: url(../../public/assets/fonts/boschsans/BoschSans-RegularItalic.woff2) format("woff2");
    font-display: swap;
    font-style: italic
}

@font-face {
    font-family: boschsans;
    font-weight: 700;
    src: url(../../public/assets/fonts/boschsans/BoschSans-Bold.woff2) format("woff2");
    font-display: swap
}

@font-face {
    font-family: boschsans;
    font-weight: 700;
    src: url(../../public/assets/fonts/boschsans/BoschSans-BoldItalic.woff2) format("woff2");
    font-display: swap;
    font-style: italic
}

/* BOSCH SANS SIMPLIFIED CHINESE */
@font-face {
    font-family: boschsans-zh;
    font-weight: 400;
    src: url(../../public/assets/fonts/boschsans-zh/BoschSans-zh-Regular.woff2) format("woff2");
    font-display: swap
}

@font-face {
    font-family: boschsans-zh;
    font-weight: 700;
    src: url(../../public/assets/fonts/boschsans-zh/BoschSans-zh-Bold.woff2) format("woff2");
    font-display: swap
}

/* BOSCH SANS JAPANESE */
@font-face {
    font-family: boschsans-jp;
    font-weight: 400;
    src: url(../../public/assets/fonts/boschsans-jp/BoschSans-jp-Regular.woff2) format("woff2");
    font-display: swap
}

@font-face {
    font-family: boschsans-jp;
    font-weight: 700;
    src: url(../../public/assets/fonts/boschsans-jp/BoschSans-jp-Bold.woff2) format("woff2");
    font-display: swap
}
